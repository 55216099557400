import React, { useState } from "react";
import axios from "axios"; // For API requests

const RequestCallbackModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Submit form data
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("http://localhost:5005/api/send-email", formData);
      alert("Your request has been submitted!");
      onClose();
    } catch (error) {
      alert("Failed to send your request. Please try again.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-hidden" style={{ zIndex: 9999 }}>
      <div className="bg-[linear-gradient(135deg,#FFC0D7,#FF6F91),linear-gradient(to-r,#1E3A8A,#1E40AF)] p-1 rounded-2xl shadow-2xl w-full max-w-2xl relative overflow-hidden">
        <div className="bg-white p-8 rounded-xl shadow-lg w-full">
          <h2 className="text-3xl font-bold mb-4 text-center text-[#02123c]">
            Request Callback
          </h2>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="form-label text-gray-800 font-semibold">Full Name:</label>
              <input
                type="text"
                name="fullName"
                className="form-control border border-gray-300 rounded-md w-full p-2 focus:ring-2 focus:ring-[#02123c]"
                placeholder="Enter your full name"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label text-gray-800 font-semibold">Email:</label>
              <input
                type="email"
                name="email"
                className="form-control border border-gray-300 rounded-md w-full p-2 focus:ring-2 focus:ring-[#02123c]"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label text-gray-800 font-semibold">Phone Number:</label>
              <input
                type="tel"
                name="phoneNumber"
                className="form-control border border-gray-300 rounded-md w-full p-2 focus:ring-2 focus:ring-[#02123c]"
                placeholder="Enter your phone number"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label text-gray-800 font-semibold">Message:</label>
              <textarea
                name="message"
                className="form-control border border-gray-300 rounded-md w-full p-2 focus:ring-2 focus:ring-[#02123c]"
                placeholder="Enter your message"
                rows="3"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <button type="submit" className="w-full bg-[#02123c] text-white py-2 rounded-md shadow-md hover:bg-[#01112f]">
              Avail This Offer
            </button>
          </form>

          <button className="absolute top-3 right-4 text-gray-600 hover:text-gray-900 text-2xl" onClick={onClose}>
            &times;
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestCallbackModal;
