import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { NextArrow, PrevArrow } from "./CustomArrows"; // Adjust the import path 
import romanticescape from "../images/romanticescape.mp4";
import Bali from "../images/bali.jpg";
import malasyia from "../images/malaysia.jpg";
import bhutan from "../images/bhutan.jpeg";
import thailand from "../images/thailand.jpg";
import mauritius from "../images/mauritius.png";
import Maldives from "../images/maldives.jpg";
import kashmir from "../images/kashmir.png";
import kerala from "../images/kerla.jpg";
import andaman from "../images/andman.jpg";
import Turkey from "../images/turkey.png";
import Almaty from "../images/almaty.png";


const Romanticescape = () => {
  const service = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="services" className="services-area pt-113 pb-70 mt-50">
      <div className="video-background-wrapper">
        <video autoPlay muted loop className="video-background">
          <source src={romanticescape} type="video/mp4" />
        </video>
        <div className="video-overlay"></div>

        <div className="container slider-container">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="section-title center-align mb-50 text-center section-header">
                <h2 className="text-white">Romantic Escape</h2>
              </div>
            </div>
          </div>
          <Slider className="services-active" {...service}>
            {/* Rajasthan */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={Bali} alt="Bali" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Bali</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>


            {/* Kashmir */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={malasyia} alt="malasyia" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Malasyia</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>



            {/* Northeast */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={thailand} alt="thailand" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Thailand</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            {/* Kerala */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={mauritius} alt="mauritius" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Mauritius</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            {/* Andaman */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={Maldives} alt="maldives" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Maldives</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="single-service-card position-relative">
                <img src={kashmir} alt="Kashmir" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Kashmir</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="single-service-card position-relative">
                <img src={kerala} alt="Kerala" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Kerala</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="single-service-card position-relative">
                <img src={andaman} alt="Andaman" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Andaman</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

         

          

            {/* Uttarakhand */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={Bali} alt="Bali" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Bali</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            {/* Arunachal Pradesh */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={Turkey} alt="Turkey" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Turkey</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={Almaty} alt="Almaty" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Almaty</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Romanticescape;
