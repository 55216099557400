import React from "react";
import Slider from "react-slick";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faHiking, faSmile, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';

// Import your images here
import Thailand from "../images/frame1.png";
import Vietnam from "../images/frame2.png";
import Bhutan from "../images/frames3.png";
import Kashmir from "../images/frames4.png";
import Japan from "../images/frames5.jpg"; // Add your new image here
import Italy from "../images/frames6.jpg"; // Add your new image here

function JourneyInFrames() {
    const destinations = [
        { img: Thailand },
        { img: Vietnam },
        { img: Bhutan },
        { img: Kashmir },
        { img: Japan }, // New destination
        { img: Italy }, // New destination
    ];

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 700,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <BsArrowRightCircle className="text-dark text-2xl cursor-pointer" />,
        prevArrow: <BsArrowLeftCircle className="text-dark text-2xl cursor-pointer" />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="container py-5">
            <div className="text-center mb-4">
                <h2 className="display-6 fw-bold creative-heading" style={{ color: '#02123c' }}>Journey in Frames</h2>
                <p className="lead text-secondary">Pictures Perfect Moments</p>
                
                {/* Font Awesome Icons Section */}
                <div className="d-flex justify-content-center gap-3 my-3">
                    <div className="text-center">
                        <FontAwesomeIcon icon={faGlobe} size="2x" className="text-primary mb-1" />
                        <p className="small text-muted">Explore</p>
                    </div>
                    <div className="text-center">
                        <FontAwesomeIcon icon={faHiking} size="2x" className="text-success mb-1" />
                        <p className="small text-muted">Adventure</p>
                    </div>
                    <div className="text-center">
                        <FontAwesomeIcon icon={faSmile} size="2x" className="text-warning mb-1" />
                        <p className="small text-muted">Joy</p>
                    </div>
                    <div className="text-center">
                        <FontAwesomeIcon icon={faMapMarkedAlt} size="2x" className="text-danger mb-1" />
                        <p className="small text-muted">Discover</p>
                    </div>
                </div>
            </div>

            <Slider {...sliderSettings} className="px-2" style={{ perspective: "1000px" }}>
                {destinations.map((destination, index) => (
                    <div key={index} className="px-3" style={{ transformStyle: "preserve-3d" }}>
                        <div 
                            className="position-relative overflow-hidden"
                            style={{
                                paddingTop: "97.5%", // Maintain a 270:400 aspect ratio
                                width: "100%",
                                borderRadius: "12px", // Curved border
                                overflow: "hidden",
                                transition: "0.3s ease",
                            }}
                        >
                            <img
                                src={destination.img}
                                alt=""
                                className="position-absolute top-0 start-0 w-100 h-100"
                                style={{
                                    objectFit: "cover",
                                    transition: "0.3s ease",
                                }}
                            />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default JourneyInFrames;
