import React, { useEffect, useState } from "react";
import Sliderhome from "./Sliderhome";
import Internationaldestination from "./Internationaldestination";
import Whychooseus from "./Whychooseus";
import HolidaysByTheme from "./HolidaysByTheme";
import Testimonial from "./Testimonial";
import TravelCategoriesSection from "./TravelCategoriesSection";
import CallToAction from "./CallToAction";
import GetInTouch from "./GetInTouch";
import Brand from "./Brand";
import Footer from "./Footer";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { NextArrow, PrevArrow } from "./CustomArrows";
import BGExplore from "../images/explore-india.png";
import Romanticescape from "./Romanticescape";
import Visaonarrival from "./Visaonarrival";
import RequestCallbackModal from "./RequestCallbackModal"; // New Modal component
import Counter from "./Counter";
import JourneyInFrames from "./JourneyInFrames";

const Home = () => {
  const service = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [toursData, setToursData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadTours();
  }, []);

  // Fetch India tours
  const loadTours = async () => {
    try {
      const response = await fetch("http://localhost:5005/tour/Fetch");
      const data = await response.json();
      setToursData(data);
    } catch (error) {
      console.error("Error fetching tours:", error);
    }
  };

  return (
    <div>
      <Sliderhome />

      {/* Explore India Section */}
      <section id="services" className="services-area pt-113 pb-70 mt-50 relative">
        <div className="absolute inset-0">
          <img src={BGExplore} alt="Background Explore" className="w-full h-full object-cover" />
         
        </div>

        <div className="relative z-10 container slider-container">
          <div className="row justify-content-center">
            <div className="col-xl-12 text-center">
              <h2 className="text-center font-bold text-4xl py-5 mb-0 text-white">
                Explore India
              </h2>
            </div>
          </div>

          <Slider className="services-active" {...service}>
            {toursData.length > 0 ? (
              toursData.map((tourItem) => (
                <div key={tourItem.id} className="px-2 mb-5">
<div className="single-service-card">
  <img
    src={tourItem.tourImage}
    alt={tourItem.tourName}
    className="w-full h-40 object-cover"
  />
  <div className="p-4 text-white bg-gradient-to-t from-black/60 to-transparent absolute inset-0 flex flex-col justify-end items-center">
    <h4 className="text-xl font-extrabold mb-2">
      <Link to="/itinerary" className="text-white no-underline">
        {tourItem.tourName}
      </Link>
    </h4>
    <button
      className="bg-white text-black font-semibold text-lg py-2 px-6 rounded-full shadow-md"
      onClick={() => setIsModalOpen(true)}
    >
      Request Callback
    </button>
  </div>
</div>






                </div>
              ))
            ) : (
              <p>Loading...</p>
            )}
          </Slider>
        </div>
        
      </section>

       {/* Explore India Section */}
       <section id="services" className="services-area pt-113 pb-70 mt-50 relative">
        <div className="absolute inset-0">
          <img src={BGExplore} alt="Background Explore" className="w-full h-full object-cover" />
        
        </div>

        
      </section>





      {/* Request Callback Modal */}
      {isModalOpen && (
        <RequestCallbackModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      )}

      {/* Other Sections */}
      <Internationaldestination/>
      <Visaonarrival/>
      <Romanticescape/>
      <Whychooseus />
      <HolidaysByTheme />
      <Testimonial />
     <JourneyInFrames/>
      <TravelCategoriesSection />
      <CallToAction />

      <GetInTouch />
      <Counter/>
      <Brand />
      <Footer />
    </div>
  );
};

export default Home;
