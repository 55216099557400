import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faCommentDots } from '@fortawesome/free-solid-svg-icons';


const GetInTouch = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add validation and submission logic here
  };

  return (
    <section className="bg-pattern py-8">
      <div className="container mx-auto">
        <h2 className="text-center mb-6 text-3xl text-white font-bold">Get in Touch With Us</h2>
        <div className="flex flex-wrap items-stretch">
          {/* Form Section */}
          <div className="w-full md:w-1/2 p-4 flex">
            <form className="bg-white rounded-lg shadow-md p-8 w-full" onSubmit={handleSubmit}>
              {/* Full Name Input */}
              <div className="relative mb-6">
                <FontAwesomeIcon icon={faUser} className="absolute left-4 top-4 text-gray-500" />
                <input 
                  type="text" 
                  name="fullName" 
                  id="fullName" 
                  className="pl-12 h-12 w-full border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#02123c] input-field" 
                  placeholder="John Doe" 
                  value={formData.fullName} 
                  onChange={handleChange} 
                />
                <label htmlFor="fullName" className="input-label">Full Name</label>
              </div>

              {/* Email Input */}
              <div className="relative mb-6">
                <FontAwesomeIcon icon={faEnvelope} className="absolute left-4 top-4 text-gray-500" />
                <input 
                  type="email" 
                  name="email" 
                  id="email" 
                  className="pl-12 h-12 w-full border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#02123c] input-field" 
                  placeholder="example@domain.com" 
                  value={formData.email} 
                  onChange={handleChange} 
                />
                <label htmlFor="email" className="input-label">Email</label>
              </div>

              {/* Phone Number Input */}
              <div className="relative mb-6">
                <FontAwesomeIcon icon={faPhone} className="absolute left-4 top-4 text-gray-500" />
                <input 
                  type="tel" 
                  name="phoneNumber" 
                  id="phoneNumber" 
                  className="pl-12 h-12 w-full border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#02123c] input-field" 
                  placeholder="(123) 456-7890" 
                  value={formData.phoneNumber} 
                  onChange={handleChange} 
                />
                <label htmlFor="phoneNumber" className="input-label">Phone Number</label>
              </div>

              {/* Message Input */}
              <div className="relative mb-6">
                <FontAwesomeIcon icon={faCommentDots} className="absolute left-4 top-4 text-gray-500" />
                <textarea 
                  name="message" 
                  id="message" 
                  className="pl-12 h-32 w-full border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#02123c] input-field" 
                  placeholder="Your message here" 
                  value={formData.message} 
                  onChange={handleChange} 
                ></textarea>
                <label htmlFor="message" className="input-label">Message</label>
              </div>

              <button 
                type="submit" 
                className="w-full h-12 bg-[#02123c] text-white rounded-md hover:bg-[#FFC0D7] transition-all duration-300"> 
                Connect Now 
              </button>
            </form>
          </div>

          {/* Image Section */}
          <div className="w-full md:w-1/2 p-4 flex justify-center">
            <div className="rounded-lg shadow-lg bg-white p-4 flex items-center justify-center" style={{ height: '100%' }}>
              <img 
                src="https://images.pexels.com/photos/63572/pexels-photo-63572.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
                alt="Creative Illustration" 
                className="rounded-lg max-w-full h-100 shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
