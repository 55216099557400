import React from "react";
import { Link } from "react-router-dom";
import logo from '../images/logo-new.png'; // Import the logo

const Footer = () => {
  return (
    <footer className="footer-bg">
      {/* Wavy Shape */}
      <svg
        className="wave"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
        style={{ width: "100%", height: "100px", position: "relative" }}
      >
        <path
          fill="#02123c"
          d="M0,64L30,96C60,128,120,192,180,208C240,224,300,192,360,160C420,128,480,96,540,74.7C600,53,660,43,720,64C780,85,840,139,900,176C960,213,1020,235,1080,224C1140,213,1200,171,1260,138.7C1320,107,1380,85,1410,74.7L1440,64L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320H0Z"
        ></path>
      </svg>

      <div className="footer-content">
        <div className="container">
          <div className="row g-4"> {/* g-4 adds space between columns */}
            {/* Column 1 */}
            <div className="col-lg-3 col-md-6 mb-4 pb-2">
              <div className="footer-widget">
                <img src={logo} alt="logo" className="mb-3 w-100" /> {/* Use w-100 for responsiveness */}
                <h2 className="footer-heading text-center">Join Us on a Journey of a Lifetime!</h2>
                <p className="footer-text text-light">
                  Whether you seek spiritual enlightenment, adventure, cultural immersion, or luxury escapes, The Tripping Tales has a package designed just for you.
                </p>
              </div>
            </div>
            {/* Column 2 */}
            <div className="col-lg-3 col-md-6 mb-4 pb-2">
              <div className="footer-widget">
                <h2 className="footer-heading text-white">Contact Us</h2>
                <div className="f-contact">
                  <ul className="list-unstyled text-light">
                    <li className="mb-2">
                      <i className="icon fas fa-phone"></i>
                      <span>+91-9462981814</span>
                    </li>
                    <li className="mb-2">
                      <i className="icon fas fa-phone"></i>
                      <span>+91-7976902301</span>
                    </li>
                    <li className="mb-2">
                      <i className="icon fas fa-envelope"></i>
                      <Link to="#" className="text-light">info@thetrippingtales.com</Link>
                    </li>
                    <li className="mb-2">
                      <i className="icon fas fa-envelope"></i>
                      <Link to="#" className="text-light">sales@thetrippingtales.com</Link>
                    </li>
                    <li className="mb-2">
                      <i className="icon fas fa-map-marker-alt"></i>
                      <Link to="#" className="text-light">
                        117 Milap Nagar, Opp. Govt Jaipuria Hospital, Tonk Road, Jaipur, <br />
                        Rajasthan, 302018
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Column 3 */}
            <div className="col-lg-3 col-md-6 mb-4 pb-4">
              <div className="footer-widget">
                <h2 className="footer-heading text-white">Our Services</h2>
                <div className="footer-link">
                  <ul className="list-unstyled text-light">
                    <li>
                      <Link to="/faq" className="text-light">FAQ</Link>
                    </li>
                    <li>
                      <Link to="#" className="text-light">Support</Link>
                    </li>
                    <li>
                      <a href="/PrivacyPolicy" target="_blank" rel="noopener noreferrer" className="text-light">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/CopyrightPolicy" target="_blank" rel="noopener noreferrer" className="text-light">Copyright Policy</a>
                    </li>
                    <li>
                      <a href="/TermsandConditions" target="_blank" rel="noopener noreferrer" className="text-light">Terms and Conditions</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Column 4 */}
            <div className="col-lg-3 col-md-6 mb-4 pb-2">
              <div className="footer-widget">
                <h2 className="footer-heading text-white">Top Destinations</h2>
                <div className="footer-link">
                  <ul className="list-unstyled text-light">
                    <li>
                      <Link to="/TourPackage" className="text-light">Bali</Link>
                    </li>
                    <li>
                      <Link to="/TourPackage" className="text-light">Malaysia</Link>
                    </li>
                    <li>
                      <Link to="/destination/tokyo" className="text-light">Thailand</Link>
                    </li>
                    <li>
                      <Link to="/TourPackage" className="text-light">Mauritius</Link>
                    </li>
                    <li>
                      <Link to="/TourPackage" className="text-light">Maldives</Link>
                    </li>
                  </ul>
                </div>
                {/* Move Social Links here */}
                <div className="footer-social mt-9 mb-0">
                  <Link to="#" className="social-icon text-sm">
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                  <Link to="#" className="social-icon text-sm">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="https://www.instagram.com/the_trippingtales/" className="social-icon text-sm">
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link to="https://www.linkedin.com/company/the-tripping-tales/" className="social-icon text-sm">
                    <i className="fab fa-linkedin"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap bg-pink text-white py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <p className="mb-0 text-black">
                Copyright &copy; {new Date().getFullYear()} The Tripping Tales. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
