import React, { useState } from "react"; // Import useState from React
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NextArrow, PrevArrow } from "./CustomArrows"; // Adjust the import path as needed
import BGImage1 from "../images/adventure.png"; // Replace with your actual image paths
import BGImage2 from "../images/culturaltour.jpg";
import BGImage3 from "../images/luxury.jpg";
import BGImage4 from "../images/familytour.jpg";
import BGImage5 from "../images/romanticgateways.jpg";
import RequestCallbackModal from "./RequestCallbackModal"; // New Modal component

function Sliderhome() {
  const [isModalOpen, setIsModalOpen] = useState(false); // Use useState directly

  const home = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="home" className="slider-area fix p-relative">
      <Slider className="slider-active" {...home}>
        {[
          {
            image: BGImage1,
            title: "Adventure Tours",
            description:
              "Experience high-energy activities such as hiking, rock climbing, rafting, skydiving, and zip-lining.",
          },
          {
            image: BGImage2,
            title: "Cultural Tour",
            description:
              "Immerse in the local history, traditions, and art. Explore historical landmarks, museums, cultural festivals, and interactions with local artisans and communities.",
          },
          {
            image: BGImage3,
            title: "Luxury Tour",
            description:
              "Enjoy high-end, exclusive experiences with premium accommodations, private transfers, gourmet dining, and personalized services, all designed for ultimate comfort, privacy, and sophistication.",
          },
          {
            image: BGImage4,
            title: "Family Tours",
            description:
              "Perfect for all ages, offering theme parks, educational tours, outdoor adventures, and family-friendly resorts that ensure fun and safety for both children and adults.",
          },
          {
            image: BGImage5,
            title: "Romantic Getaways",
            description:
              "Take your partner for an intimate experience featuring luxurious accommodations, candlelit dinners, couples' spa treatments, and scenic destinations perfect for honeymoons and anniversaries.",
          },
        ].map((slide, index) => (
          <div className="single-slider slider-bg d-flex align-items-center" key={index}>
            <img src={slide.image} alt={slide.title} className="image-background" />
            <div className="slider-overlay">
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-7 col-md-7 text-center">
                    <div className="slider-content s-slider-content pt-135">
                      <h2 className="animated-heading">{slide.title}</h2>
                      <p className="text-white">{slide.description}</p>
                      <div className="slider-btn mt-30 mb-105">
                      <button
  className="enquiry-button animated-button"
  onClick={() => setIsModalOpen(true)}
>
  Enquiry Now
</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      
      {/* Render your modal here */}
      {isModalOpen && (
        <RequestCallbackModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      )}
    </section>
  );
}

export default Sliderhome;
