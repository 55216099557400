import React from "react";
import { Link } from "react-router-dom";
import himalyanTreks from "../images/himalyan-treks.png";
import spritualTour from "../images/spritualtour.png";
import wildEscape from "../images/wildescape.png";
import groupTours from "../images/grouptours.png";

function HolidaysByTheme() {
  return (
    <>
      <section id="pricing" className="pricing-area pt-6 pb-6">
        {" "}
        {/* Reduced padding */}
        <div className="container justify-center">
          <div className="row justify-center">
            <div className="col-lg-4 col-md-12">
              <div className="section-title mb-0">
                {" "}
                {/* Reduced margin */}
                <div className="flex justify-between items-center">
                  <h2 className="creative-heading1 animated-heading testimonial-heading">
                    Holidays by Theme
                  </h2>
                </div>
              </div>
              <p className="text-sm text-gray-600 leading-relaxed text-justify">
                At The Tripping Tales, we wholeheartedly embrace the profound
                spiritual essence of India—a land where ancient wisdom flows
                like an undercurrent through every corner and whispers from the
                past echo in every stone. India’s spiritual legacy spans
                millennia, nurturing traditions that invite seekers from all
                walks of life and from all over the world to connect with its
                sacred heartbeat. Whether you are on a quest for inner peace,
                spiritual enlightenment, or a deeper sense of purpose, India’s
                revered sites promise a transformative journey that harmonizes
                both self-discovery and divine connection. Our meticulously
                crafted pilgrimage tours are thoughtfully designed to guide you
                through an array of sacred destinations that encompass the vast
                cultural and geographical diversity of this extraordinary
                nation. From the awe-inspiring Hindu temples perched high in the
                Himalayas to the intricately carved Jain temples of Rajasthan,
                each site we visit offers not only a visual marvel but also a
                gateway to spiritual insight and reflection. Experience the
                gentle serenity as you walk through the corridors of
                centuries-old gurudwaras, where the Sikh faith’s timeless
                principles of equality and selfless service resonate deeply.
                Feel the solemn tranquility of India’s grand mosques, each a
                magnificent symbol of the Islamic heritage that has shaped
                India’s soul. For Christian pilgrims, our journeys bring you to
                historic churches, many of which have stood since the earliest
                days of Christianity in India, nearly two thousand years ago,
                offering a sanctuary for reflection and renewal. And for those
                drawn to the path of mindfulness, the Buddhist monasteries of
                Ladakh and Sikkim offer peaceful retreats amidst stunning
                natural beauty. These monasteries, often nestled in serene
                landscapes, provide a space for meditation, simplicity, and
                profound self-reconnection.
              </p>
            </div>

            <div className="col-lg-8 col-md-12">
              <div className="row">
                {/* Cards */}
                {[
                  {
                    img: himalyanTreks,
                    title: "Himalayan Treks",
                    link: "/pilgrimage",
                  },
                  {
                    img: spritualTour,
                    title: "Spiritual Tour",
                    link: "/wild-escapes",
                  },
                  {
                    img: wildEscape,
                    title: "Wild Escape",
                    link: "/group-tours",
                  },
                  {
                    img: groupTours,
                    title: "Group Tours",
                    link: "/honeymoon-packages",
                  },
                ].map((theme, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="card bg-white shadow-lg hover:shadow-2xl rounded-lg overflow-hidden transition duration-300 mt-3">
                      <img
                        src={theme.img}
                        alt={theme.title}
                        className="w-full h-40 object-cover"
                      />{" "}
                      {/* Reduced height */}
                      <div className="p-4 flex justify-between items-center">
                        <h3 className="text-sm font-semibold text-gray-800">
                          {theme.title}
                        </h3>
                        <Link to={theme.link} className="secondary-button">
                          View More <i className="fas fa-angle-right ml-2"></i>
                        </Link>{" "}
                        {/* Styled 'View More' button */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HolidaysByTheme;
