import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import TopBar from './components/Topbar';
import Sliderhome from './components/Sliderhome';
import ExploreIndia from './components/ExploreIndia';
import Internatinaldestination from './components/Internationaldestination';
import Romanticescape from './components/Romanticescape';
import Visaonarrival from './components/Visaonarrival';
import Whychooseus from './components/Whychooseus';
import HolidaysByTheme from './components/HolidaysByTheme';
import Testimonial from './components/Testimonial';
import TravelCategoriesSection from './components/TravelCategoriesSection';
import CallToAction from './components/CallToAction';
import GetInTouch from './components/GetInTouch';
import Footer from './components/Footer';
import Brand from './components/Brand';
import Login from './components/Login'; // Import your Login component
import TourPackage from './components/TourPackage'; // Import your TourPackage component
import Itinerary from './components/Itinerary';
import Home from './components/Home';
import PrivacyPolicy from './components/PrivacyPolicy';
import Signup from './components/Signup';
import CopyrightPolicy from './components/CopyrightPolicy';
import TermsandConditions from './components/TermsandConditions';
import FAQ from './components/Faq';




function App() {
  return (
    <Router>
      <div>
        {/* <TopBar /> */}
        <Navbar />

        {/* Define routes */}
        <Routes>
          <Route path="/" element={
            <>
              <Home/>
            </>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/tourpackage" element={
            <>
              <TourPackage />
              <Footer />  {/* Footer added here */}
            </>
          } />
           <Route path="/itinerary" element={
            <>
              <Itinerary />
              <Footer />  {/* Footer added here */}
            </>
          } />
            <Route path="/privacypolicy" element={
            <>
              <PrivacyPolicy />
              <Footer />  {/* Footer added here */}
            </>
          } />
            <Route path="/copyrightpolicy" element={
            <>
              <CopyrightPolicy />
              <Footer />  {/* Footer added here */}
            </>
          } />
           <Route path="/termsandconditions" element={
            <>
              <TermsandConditions />
              <Footer />  {/* Footer added here */}
            </>
          } />
             <Route path="/faq" element={
            <>
              <FAQ />
              <Footer />  {/* Footer added here */}
            </>
          } />
             <Route path="/modal" element={
            <>
          
              <Footer />  {/* Footer added here */}
            </>
          } />

          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
