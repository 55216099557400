// CounterSection.js
import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faHiking, faSmile, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const Counter = () => {
  const [resetTrigger, setResetTrigger] = useState(false);

  // Toggle the resetTrigger every time a count finishes
  const handleEnd = () => {
    setResetTrigger((prev) => !prev);
  };

  return (
    <div className="bg-gradient-to-r from-[#D1E8FF] to-[#FFC0D7] py-20">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          
          {/* Counter 1 */}
          <div className="bg-[#02123c] rounded-xl p-6 shadow-lg flex flex-col items-center transition-transform transform hover:scale-105 text-white hover:shadow-2xl hover:bg-[#002359]">
            <FontAwesomeIcon icon={faMapMarkedAlt} className="text-5xl mb-4 text-[#ffc0d7]" />
            <h2 className="text-5xl font-extrabold">
              <CountUp
                key={resetTrigger}
                start={0}
                end={500}
                duration={6}
                onEnd={handleEnd}
              />+
            </h2>
            <p className="text-lg mt-2 font-medium">Epic Journeys</p>
          </div>
          
          {/* Counter 2 */}
          <div className="bg-[#02123c] rounded-xl p-6 shadow-lg flex flex-col items-center transition-transform transform hover:scale-105 text-white hover:shadow-2xl hover:bg-[#002359]">
            <FontAwesomeIcon icon={faGlobe} className="text-5xl mb-4 text-[#ffc0d7]" />
            <h2 className="text-5xl font-extrabold">
              <CountUp
                key={resetTrigger}
                start={0}
                end={30}
                duration={6}
                onEnd={handleEnd}
              />+
            </h2>
            <p className="text-lg mt-2 font-medium">Continents & Countries Tours</p>
          </div>
          
          {/* Counter 3 */}
          <div className="bg-[#02123c] rounded-xl p-6 shadow-lg flex flex-col items-center transition-transform transform hover:scale-105 text-white hover:shadow-2xl hover:bg-[#002359]">
            <FontAwesomeIcon icon={faHiking} className="text-5xl mb-4 text-[#ffc0d7]" />
            <h2 className="text-5xl font-extrabold">
              <CountUp
                key={resetTrigger}
                start={0}
                end={50}
                duration={6}
                onEnd={handleEnd}
              />+
            </h2>
            <p className="text-lg mt-2 font-medium">Outdoor Activities</p>
          </div>
          
          {/* Counter 4 */}
          <div className="bg-[#02123c] rounded-xl p-6 shadow-lg flex flex-col items-center transition-transform transform hover:scale-105 text-white hover:shadow-2xl hover:bg-[#002359]">
            <FontAwesomeIcon icon={faSmile} className="text-5xl mb-4 text-[#ffc0d7]" />
            <h2 className="text-5xl font-extrabold">
              <CountUp
                key={resetTrigger}
                start={0}
                end={500}
                duration={6}
                onEnd={handleEnd}
              />+
            </h2>
            <p className="text-lg mt-2 font-medium">Joyful Journeys</p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Counter;
