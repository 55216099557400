// TravelCategoriesSection.js
import React from "react";
import { Link } from "react-router-dom";

const travelCategories = [
  {
    img: "https://images.pexels.com/photos/19563354/pexels-photo-19563354/free-photo-of-snack-and-beer-on-bar-counter.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Food and Wine Tours",
    description:
      "Tailored for culinary enthusiasts, with gourmet experiences, cooking classes, wine tastings, and more.",
    link: "/food-wine-tours",
  },
  {
    img: "https://images.pexels.com/photos/14604246/pexels-photo-14604246.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Eco-Tours",
    description:
      "Prioritizing sustainability, enjoy wildlife conservation activities, nature walks, and eco-friendly travel.",
    link: "/eco-tours",
  },
  {
    img: "https://images.pexels.com/photos/17507607/pexels-photo-17507607/free-photo-of-couple-standing-by-a-jeep-in-wet-flat-landscape.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Solo Travel",
    description:
      "Designed for solo travelers with group activities, social events, and chances to meet fellow travelers.",
    link: "/solo-travel",
  },
  {
    img: "https://images.pexels.com/photos/1472887/pexels-photo-1472887.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Wellness Retreats",
    description:
      "Relax with yoga, meditation, spa treatments, and wellness workshops in serene natural environments.",
    link: "/wellness-retreats",
  },
  {
    img: "https://images.pexels.com/photos/17824132/pexels-photo-17824132/free-photo-of-hill-in-the-hobbiton-movie-set.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Budget Tours",
    description:
      "Affordable travel with budget-friendly accommodations, local transportation, and memorable experiences.",
    link: "/budget-tours",
  },
];

const TravelCategoriesSection = () => {
  return (
    <section className="travel-categories-section">
      <div className="container">
        <h2 className="creative-heading2 text-center">Explore Our Travel Categories</h2>
        <div className="categories-grid">
          {travelCategories.map((category, index) => (
            <div className="category-card" key={index}>
              <img src={category.img} alt={category.title} className="category-img" />
              <div className="category-content">
                <h3 className="category-title">{category.title}</h3>
                <p className="category-description">{category.description}</p>
                
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TravelCategoriesSection;
