import React, { useState } from "react"; // Import useState from React
import RequestCallbackModal from "./RequestCallbackModal"; // Import your modal component

const CallToAction = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Use useState directly

  return (
    <>
      <section className="cta-section">
        <div className="container">
          <div className="row justify-content-center align-items-center cta-background" 
               style={{ borderRadius: '20px', overflow: 'hidden' }}>
            <div className="col-lg-12 col-md-12 text-center cta-content">
              <h2 className="creative-heading">Dreaming of Your Next Adventure?</h2>
              <p className="cta-subtitle">Hit us!</p>
              <button
  className="bg-white text-black font-semibold text-lg py-2 px-6 rounded-full shadow-md transition-all duration-300 ease-in-out hover:bg-black hover:text-white hover:border hover:border-white"
  onClick={() => setIsModalOpen(true)}
>
  Enquiry Now
</button>

            </div>
          </div>
        </div>
      </section>

      {/* Render the modal */}
      {isModalOpen && (
        <RequestCallbackModal 
          isOpen={isModalOpen} 
          onClose={() => setIsModalOpen(false)} 
        />
      )}
    </>
  );
};

export default CallToAction;
