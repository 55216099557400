import React from 'react';
import Slider from 'react-slick';
import Brand1 from '../images/abudhabi.png';
import Brand2 from '../images/singaporetourism.png';
import Brand3 from '../images/jordan.png';
import Brand4 from '../images/indonesia.png';
import Brand5 from '../images/rajasthan-tourism.png';
import Brand6 from '../images/uttarakhand-logo.png';
import Brand7 from '../images/goturkey-logo.png';

function Brand() {
    const brandSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500, // Faster transition speed
        autoplay: true,
        autoplaySpeed: 1500, // Faster autoplay speed
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="brand-area mt-10">
            <div className="container">
                <h2 className="text-center mb-4 text-2xl lg:text-3xl font-semibold text-[#02123c]">
                    Our Partners
                </h2>
                <Slider className="brand-active" {...brandSettings}>
                    {[Brand1, Brand2, Brand3, Brand4, Brand5, Brand6, Brand7].map((brand, index) => (
                        <div 
                            className="col-6 col-sm-4 col-md-3 col-lg-2 px-2" 
                            key={index} 
                        >
                            <div 
                                className="single-brand w-full h-full p-4 bg-white flex items-center justify-center rounded-lg transition-transform duration-300 hover:scale-105"
                            >
                                <img
                                    src={brand}
                                    alt={`Brand ${index + 1}`}
                                    className="brand-img w-full h-full max-w-[120px] max-h-[120px] object-contain rounded-md"
                                />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Brand;
