import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Hotel1 from "../images/expert-guide.svg";
import Hotel2 from "../images/comfort.svg";
import Hotel3 from "../images/personalized-service.svg";
import Hotel4 from "../images/cultural-immersion.svg";
import Hotel5 from "../images/recognize.svg";
import Hotel6 from "../images/hassle.svg";

function Whychooseus() {
  const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 1200,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const services = [
    {
      img: Hotel1,
      title: "Expert Guides",
      description:
        "Our knowledgeable guides provide insightful and enriching experiences.",
      link: "/service-details",
    },
    {
      img: Hotel2,
      title: "Comfort & Convenience",
      description:
        "We offer comfortable transportation and well-appointed accommodations.",
      link: "/service-details",
    },
    {
      img: Hotel3,
      title: "Personalized Service",
      description: "Tailored packages to meet your unique travel desires.",
      link: "/service-details",
    },
    {
      img: Hotel4,
      title: "Cultural Immersion",
      description: "Deep engagement with local traditions and rituals.",
      link: "/service-details",
    },
    {
      img: Hotel5,
      title: "24/7 Support",
      description: "We provide round-the-clock customer support.",
      link: "/service-details",
    },
    {
      img: Hotel6,
      title: "Hassle-Free Tours",
      description: "Enjoy a seamless and worry-free travel experience.",
      link: "/service-details",
    },
  ];

  return (
    <section className="pb-16 whychoose">
      {" "}
      {/* Updated padding */}
      <div className="container mx-auto px-4 pb-0 pt-5">
        <div className="text-center p-2 mt-0 mb-0 pb-0 explore-padding section-header">
          <h5>Explore</h5>
          <h2 className="animated-heading testimonial-heading">
            Why choose The Tripping Tales?
          </h2>
        </div>

        <Slider {...sliderSettings}>
          {services.map((service, index) => (
            <div key={index} className="p-4 mt-6">
              {" "}
              {/* Margin for cards */}
              <div className="why-choose-card bg-white rounded-2xl transition-all duration-300 ease-in-out flex flex-col h-full">
                <div className="flex justify-center items-center mb-6">
                  <img
                    src={service.img}
                    alt={service.title}
                    className="w-16 h-16"
                  />
                </div>
                <h3
                  className="text-xl font-bold mb-2"
                  style={{ color: "var(--secondary-color)" }}
                >
                  <Link
                    to={service.link}
                    className="hover:text-secondary-color"
                  >
                    {service.title}
                  </Link>
                </h3>
                <p className="text-gray-600 mb-2">{service.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Whychooseus;
