import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    let valid = true;

    // Basic validation
    if (!name.trim()) {
      setNameError('Please enter your name');
      valid = false;
    } else {
      setNameError('');
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email address');
      valid = false;
    } else {
      setEmailError('');
    }

    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters');
      valid = false;
    } else {
      setPasswordError('');
    }

    if (!/^\d{10}$/.test(phone)) {
      setPhoneError('Please enter a valid 10-digit phone number');
      valid = false;
    } else {
      setPhoneError('');
    }

    if (!valid) return;

    try {
      const response = await axios.post('http://localhost:5005/Signup', { name, email, password, phone });
      localStorage.setItem('token', response.data.token);
      navigate('/login');
    } catch (error) {
      setError(error.response.data.message || 'Signup failed');
    }
  };

  return (
    <div className="w-full h-screen bg-gradient-to-r from-blue-500 to-purple-600 flex justify-center items-center">
      <div className="w-full max-w-6xl mx-auto bg-white shadow-2xl rounded-lg grid grid-cols-1 lg:grid-cols-2 overflow-hidden">
        {/* Left Side - Signup Form */}
        <div className="flex flex-col justify-center p-8">
          <h2 className="text-4xl font-bold text-center text-primary-color mb-6">Create Your Account</h2>
          {error && <p className="text-red-500 text-sm text-center mb-4">{error}</p>}
          <form onSubmit={handleSignup} className="w-full">
            <div className="mb-4">
              <label className="block text-primary-color text-lg" htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                required
                className={`mt-1 p-3 border rounded-lg w-full focus:outline-none focus:ring-4 transition-all duration-300 ${nameError ? 'border-red-500' : 'border-gray-300 focus:ring-secondary-color'}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
              />
              {nameError && <p className="text-red-500 text-sm">{nameError}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-primary-color text-lg" htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                required
                className={`mt-1 p-3 border rounded-lg w-full focus:outline-none focus:ring-4 transition-all duration-300 ${emailError ? 'border-red-500' : 'border-gray-300 focus:ring-secondary-color'}`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
              {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-primary-color text-lg" htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                required
                className={`mt-1 p-3 border rounded-lg w-full focus:outline-none focus:ring-4 transition-all duration-300 ${passwordError ? 'border-red-500' : 'border-gray-300 focus:ring-secondary-color'}`}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
              {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
            </div>
            <div className="mb-6">
              <label className="block text-primary-color text-lg" htmlFor="phone">Phone Number</label>
              <input
                type="text"
                id="phone"
                required
                className={`mt-1 p-3 border rounded-lg w-full focus:outline-none focus:ring-4 transition-all duration-300 ${phoneError ? 'border-red-500' : 'border-gray-300 focus:ring-secondary-color'}`}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter your phone number"
              />
              {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
            </div>
            <button
              type="submit"
              className="w-full secondary-button"
            >
              Sign Up
            </button>
          </form>
          <div className="mt-6 text-center">
            <p className="text-primary-color">Already have an account? <a href="/login" className="text-secondary-color hover:underline">Login</a></p>
          </div>
        </div>

        {/* Right Side - Image */}
        <div className="hidden lg:flex justify-center items-center">
          <img
            src="https://images.pexels.com/photos/2422286/pexels-photo-2422286.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Signup Illustration"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;
