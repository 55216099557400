import React from "react";

const TermsandConditions = () => {
  return (
    <div className="bg-gradient-to-r from-[#D1E8FF] to-[#FFC0D7] text-[#02123c] py-10 mb-10 px-4">
      <div className="container max-w-5xl mx-auto">
        <h1 className="text-4xl font-bold mb-6 text-left pb-3">
          Terms and Conditions
        </h1>
       

        <section className="mb-4">
          <p className="text-lg">
            This web page constitutes a legal agreement governing the use of our
            website (the "Website"), www.thetrippingtales.com, and any associated
            mobile applications (collectively, the "Website"), which is owned and
            operated by The Tripping Tales Travel Solutions (collectively, "The
            Tripping Tales"). The Tripping Tales manages fulfillment and
            contracting with local suppliers globally and oversees certain stays
            and tours booked through The Tripping Tales.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Definitions</h2>
          <ul className="list-disc pl-5 text-lg">
            <li>
              “Us,” “we,” or “our” refers to The Tripping Tales, the owner of
              the Website.
            </li>
            <li>
              A “Member” is an individual who has registered with our Website to
              access and utilize its features.
            </li>
            <li>
              A “Supplier” is a Member who operates a business offering tours,
              activities, and other travel-related goods and services to the public and has registered with our Website to provide such goods/services. A Member who purchases goods/services from Suppliers is referred to as a “Customer.”
            </li>
            <li>
              A “Profile” is an online compilation of information provided by a
              Member about their business if a Supplier, or generally about themselves if a Customer. A “User” is a collective term that refers to either a Visitor or a Member. A “Visitor” is an individual who merely browses our Website without registering.
            </li>
            <li>
              All text, information, graphics, audio, video, and data available through our Website, whether accessible to all or as part of our paid features, are collectively referred to as “Content.” Content provided by our Members, whether as part of their Profile or in other submissions to our Website, is specifically referred to as “Member Content.” When we refer to our Website, this includes all Content.
            </li>
          </ul>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Limited License</h2>
          <p className="text-lg">
            The Tripping Tales grants you a non-exclusive, non-transferable,
            revocable license to access and use our Website strictly in accordance
            with our Legal Terms. Your use of our Website is solely for the
            purposes outlined herein.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Our Relationship to You</h2>
          <p className="text-lg">
            The Tripping Tales functions exclusively as a venue and does not
            engage in any other relationship with you beyond that of an
            independent contractor. Our Legal Terms do not create, nor should they be construed as creating, any agency, partnership, joint venture, employer-employee, or franchisor-franchisee relationship between you and The Tripping Tales, other Users, or our affiliates.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Legal Compliance</h2>
          <p className="text-lg">
            You agree to adhere to all applicable domestic and international laws,
            statutes, ordinances, and regulations in connection with your use of
            our Website. The Tripping Tales reserves the right to investigate any complaints or reports of violations of our Legal Terms and to take appropriate actions as deemed necessary. Such actions may include, but are not limited to, the cancellation of your Member account, reporting suspected unlawful activities to law enforcement, regulators, or other relevant authorities, and disclosing information related to your profile, email addresses, usage history, posted materials, IP addresses, and traffic information, in accordance with our Privacy Policy.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Eligibility and Registration for Membership</h2>
          <p className="text-lg">
            You may access certain content on our Website without registering as a
            Member. However, to fully utilize the features of our Website, you
            must register and become a Member. Membership is non-transferable and non-assignable, and is void where prohibited by law. By registering, you affirm that you are at least 18 years of age. If you are between the ages of 13 and 18, you confirm that you have obtained parental consent to join and become a Member. Individuals under the age of 13 are prohibited from accessing the Website.
            As a supplier, by registering, you certify that you operate a legitimate tour or travel-related business and that you are either the owner or an authorized representative of that business with the right to register it on our Website. 
            Any registration that does not comply with these requirements is unauthorized, unlicensed, and in violation of our Legal Terms. You agree to adhere to all terms and conditions outlined in our Legal Terms. The Tripping Tales reserves the exclusive right to accept or reject Membership registrations at its sole discretion, with or without providing reasons.
            Upon completing the registration process, you will create a username and password for accessing the Website. You are responsible for maintaining the confidentiality of your password and for all activities conducted under your account. You agree to promptly notify us of any unauthorized use of your password or any security breaches related to your account. The Tripping Tales will not be liable for any loss or damage resulting from your failure to maintain password security.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Information From Children</h2>
          <p className="text-lg">
            We do not knowingly solicit, collect, or retain personal information
            from individuals under the age of 13.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Supplier and Sales of Goods/Services</h2>
          <p className="text-lg">
            As a supplier, you are permitted to upload information about your business, including but not limited to your address, phone number, hours of operation, and other relevant details. By providing this information, you warrant to The Tripping Tales that it is accurate to the best of your knowledge and is not malicious, libelous, or otherwise illegal for you to transmit to our Website.
            Our Website provides suppliers with a platform to offer their products and services for sale. This service is provided solely as a convenience to our Members and does not imply that The Tripping Tales endorses any particular supplier, guarantees the quality of their products or services, or participates in the sale of such products or services. Any transactions between Customers and suppliers made through our Website are strictly between the two parties involved.
            Both suppliers and Customers agree that any issues or queries regarding the sale of products or services, including but not limited to pricing, scope of services, product features, payment terms, delivery dates, shipping, refunds, exchanges, and warranties, should be addressed directly between them.
            Suppliers who engage with Customers outside of our Website must comply with any requests from Customers to cease further communication. Failure to do so, if reported to us, may result in the termination of your Profile at our sole discretion and without prior notice.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Member Content</h2>
          <p className="text-lg">
            As a Member, any information or material you upload to our Website is considered "Member Content." By submitting Member Content, you represent and warrant that you have the legal right, whether through ownership, license, or other means, to transmit, display, and use the associated text, images, or other content. You assume full responsibility for ensuring that your Member Content does not infringe upon any third-party rights and for any legal liabilities resulting from your transmission, use, or display of Member Content.
            You acknowledge that Member Content may contain material that some individuals might find offensive, indecent, or objectionable. The Tripping Tales does not control or guarantee the quality, accuracy, or integrity of Member Content provided by Members. We are not responsible for monitoring or filtering Member Content. Should any Member Content be determined to be illegal, The Tripping Tales will cooperate with relevant authorities by providing necessary information.
            If your Member Content is reported to us as violating third-party rights (e.g., copyrights) or as being offensive or inappropriate, we may request that you modify or remove the content within a specified period. If you do not comply, we reserve the right to remove it without further notice. You also grant us the right to monitor, filter, and modify or remove Member Content at our discretion, without liability to you.
            The Tripping Tales reserves the right to publish, distribute, or otherwise use Member Content, whether on the Website or for marketing, advertising, or other business purposes, without further compensation to you.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Privacy Policy</h2>
          <p className="text-lg">
            Our Privacy Policy outlines how we collect, use, and protect your
            personal information. By using our Website, you acknowledge and agree to
            the terms outlined in our Privacy Policy.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Limitation of Liability</h2>
          <p className="text-lg">
            The Tripping Tales will not be liable for any direct, indirect, incidental,
            special, consequential, or punitive damages, or any damages whatsoever,
            whether in an action of contract, negligence, or other tortious action,
            arising out of or in connection with the use of our Website or its
            Content, even if we have been advised of the possibility of such damages.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Indemnification</h2>
          <p className="text-lg">
            You agree to indemnify, defend, and hold harmless The Tripping Tales, its
            affiliates, and their respective officers, directors, employees, and agents
            from any claims, liabilities, damages, losses, and expenses, including
            attorney's fees, arising out of or in connection with your use of our
            Website, your violation of our Legal Terms, or any infringement of third
            party rights.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Termination</h2>
          <p className="text-lg">
            The Tripping Tales reserves the right to terminate your access to our
            Website at any time, without notice, for any conduct that we believe
            violates these Legal Terms or is harmful to other Users or our
            interests.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Governing Law</h2>
          <p className="text-lg">
            These Legal Terms shall be governed by and construed in accordance with the
            laws of the jurisdiction in which The Tripping Tales operates, without
            regard to its conflict of law principles.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Changes to Terms and Conditions</h2>
          <p className="text-lg">
            We reserve the right to modify these Terms and Conditions at any time
            without prior notice. Any changes will be effective immediately upon
            posting on our Website. Your continued use of the Website following any
            changes constitutes your acceptance of the modified Terms and Conditions.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Contact Information</h2>
          <p className="text-lg mb-20">
            If you have any questions regarding these Terms and Conditions, please
            contact us at info@thetrippingtales.com.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsandConditions;
