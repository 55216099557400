import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { NextArrow, PrevArrow } from "./CustomArrows"; // Adjust the import path

// Background image and other images for slides
import international from "../images/almaty1.jpg";
import thailand from "../images/thailand.jpg";
import bhutan from "../images/bhutan.jpeg";
import malasyia from "../images/malaysia.jpg";
import Singapore from "../images/singapore.png";
import vietnam from "../images/vietnam.jpg";
import Dubai from "../images/dubai.jpg";
import Srilanka from "../images/srilanka.jpg";
import Baku from "../images/baku.png";
import Bali from "../images/bali.jpg";
import Turkey from "../images/turkey.png";
import Almaty from "../images/almaty.png";

const Internationaldestination = () => {
  const service = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="services" className="international-bg services-area pt-113 pb-70 mt-50">
      {/* Background Wrapper */}
      <div className="background-container">
        <img src={international} alt="Background" className="background-image" />
       
      </div>

      {/* Slider Content */}
      <div className="container slider-container">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <div className="section-title center-align mb-50 text-center section-header">
              <h2 className="animated-heading text-white">International Destination</h2>
            </div>
          </div>
        </div>
        <Slider className="services-active" {...service}>
          <div className="col">
            <div className="single-service-card position-relative">
              <img src={thailand} alt="Thailand" className="img-fluid" />
              <div className="services-content text-white">
                <h4>
                  <Link to="/room-details">Thailand</Link>
                </h4>
                <button className="callback-btn">Request Callback</button>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="single-service-card position-relative">
              <img src={bhutan} alt="Bhutan" className="img-fluid" />
              <div className="services-content text-white">
                <h4>
                  <Link to="/room-details">Bhutan</Link>
                </h4>
                <Link to="/contact" className="callback-btn">
                  Request Callback
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="single-service-card position-relative">
              <img src={malasyia} alt="Malaysia" className="img-fluid" />
              <div className="services-content text-white">
                <h4>
                  <Link to="/room-details">Malaysia</Link>
                </h4>
                <Link to="/contact" className="callback-btn">
                  Request Callback
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="single-service-card position-relative">
              <img src={Singapore} alt="Singapore" className="img-fluid" />
              <div className="services-content text-white">
                <h4>
                  <Link to="/room-details">Singapore</Link>
                </h4>
                <Link to="/contact" className="callback-btn">
                  Request Callback
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="single-service-card position-relative">
              <img src={vietnam} alt="Vietnam" className="img-fluid" />
              <div className="services-content text-white">
                <h4>
                  <Link to="/room-details">Vietnam</Link>
                </h4>
                <Link to="/contact" className="callback-btn">
                  Request Callback
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="single-service-card position-relative">
              <img src={Dubai} alt="Dubai" className="img-fluid" />
              <div className="services-content text-white">
                <h4>
                  <Link to="/room-details">Dubai</Link>
                </h4>
                <Link to="/contact" className="callback-btn">
                  Request Callback
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="single-service-card position-relative">
              <img src={Srilanka} alt="Sri Lanka" className="img-fluid" />
              <div className="services-content text-white">
                <h4>
                  <Link to="/room-details">Sri Lanka</Link>
                </h4>
                <Link to="/contact" className="callback-btn">
                  Request Callback
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="single-service-card position-relative">
              <img src={Baku} alt="Baku" className="img-fluid" />
              <div className="services-content text-white">
                <h4>
                  <Link to="/room-details">Baku</Link>
                </h4>
                <Link to="/contact" className="callback-btn">
                  Request Callback
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="single-service-card position-relative">
              <img src={Bali} alt="Bali" className="img-fluid" />
              <div className="services-content text-white">
                <h4>
                  <Link to="/room-details">Bali</Link>
                </h4>
                <Link to="/contact" className="callback-btn">
                  Request Callback
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="single-service-card position-relative">
              <img src={Turkey} alt="Turkey" className="img-fluid" />
              <div className="services-content text-white">
                <h4>
                  <Link to="/room-details">Turkey</Link>
                </h4>
                <Link to="/contact" className="callback-btn">
                  Request Callback
                </Link>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="single-service-card position-relative">
              <img src={Almaty} alt="Almaty" className="img-fluid" />
              <div className="services-content text-white">
                <h4>
                  <Link to="/room-details">Almaty</Link>
                </h4>
                <Link to="/contact" className="callback-btn">
                  Request Callback
                </Link>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Internationaldestination;
