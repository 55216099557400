import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { NextArrow, PrevArrow } from "./CustomArrows"; // Adjust the import path
import Bali from "../images/bali.jpg";
import vietnam from "../images/vietnam.jpg";
import thailand from "../images/thailand.jpg";
import mauritius from "../images/mauritios.jpg";
import Seychelles from "../images/mauritius.png";
import malasyia from "../images/malaysia.jpg";
import bhutan from "../images/bhutan.jpeg";
import Maldives from "../images/maldives.jpg";
import kashmir from "../images/kashmir.png";
import kerala from "../images/kerla.jpg";
import bahrain from "../images/bahrain.jpg"
import baharain from "../images/baharain.jpg"
import sigiriya from "../images/sigiriya.jpg"
import andaman from "../images/andman.jpg";
import Turkey from "../images/turkey.png";
import Almaty from "../images/almaty.png";
import seylechel from "../images/seylechel.jpg"

const Visaonarrival = () => {
  const service = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="services" className="visaon services-area pt-113 pb-70">
      <div className="video-background-wrapper">
      
     

        <div className="container slider-container">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="section-title text-white center-align mb-50 text-center section-header">
                <h2 className="text-white">Visa on Arrival Destinations</h2>
              </div>
            </div>
          </div>
          <Slider className="services-active" {...service}>
            {/* Rajasthan */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={Bali} alt="Bali" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Bali</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="single-service-card position-relative">
                <img src={vietnam} alt="vietnam" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Vietnam</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            {/* Northeast */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={thailand} alt="thailand" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Thailand</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            {/* Kerala */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={mauritius} alt="mauritius" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Mauritius</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>
            {/* Kerala */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={seylechel} alt="seylechel" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Seychelles</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            {/* Kashmir */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={malasyia} alt="malasyia" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Malasyia</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            {/* Andaman */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={Maldives} alt="maldives" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Maldives</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="single-service-card position-relative">
                <img src={bahrain} alt="Kashmir" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">FIJI</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="single-service-card position-relative">
                <img src={sigiriya} alt="sigiriya" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Sri Lanka</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="single-service-card position-relative">
                <img src={baharain} alt="baharain" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Bahrain</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>


            {/* Arunachal Pradesh */}
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={Turkey} alt="Turkey" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Turkey</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="single-service-card position-relative">
                <img src={Almaty} alt="Almaty" className="img-fluid" />
                <div className="services-content text-white">
                  <h4>
                    <Link to="/room-details">Almaty</Link>
                  </h4>
                  <Link to="/contact" className="callback-btn">
                    Request Callback
                  </Link>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Visaonarrival;
