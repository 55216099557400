import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css"; // Your custom styles
import logo from "../images/logo.webp"; // Import the logo

const Main = () => {
  const [menuOpen, setMenuOpen] = useState(false); // Track mobile menu
  const [submenuOpen, setSubmenuOpen] = useState({}); // Track open submenus
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
    setMenuOpen(false); // Close the menu when the route changes
  }, [path]);

  // Toggle the mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Toggle specific submenu
  const toggleSubmenu = (key) => {
    setSubmenuOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <>
      <header className="header-area header-three">
        <div id="header-sticky" className="menu-area p-2">
          <div className="container">
            <div className="second-menu">
              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12">
                  <div className="main-menu text-center items-center">
                    {/* Hamburger Button for Mobile */}
                    <button
                      className={`hamburger-btn ${menuOpen ? "is-active" : ""}`}
                      onClick={toggleMenu}
                    >
                      <span className="hamburger-icon"></span>
                    </button>

                    {/* Desktop Menu */}
                    <Link to="/">
                      <img
                        src={logo} // Use the imported logo
                        alt="Tripping Tales Logo"
                        className="img-fluid"
                        style={{ maxHeight: "70px" }}
                      />
                    </Link>
                    <nav id="desktop-menu" className="d-none d-lg-block">
                      <ul>
                        <li className="has-sub mega-menu">
                          <Link to="/">Domestic Destinations</Link>
                          <ul className="mega-menu-content">
                            <div className="mega-menu-row">
                              {/* Spiritual Tours */}
                              <div className="mega-menu-column">
                                <h4>Spiritual Tours</h4>
                                <li>
                                  <Link to="/TourPackage">Varanasi</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Prayagraj</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Ayodhya</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Rishikesh</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Haridwar</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Bodh Gaya</Link>
                                </li>
                              </div>

                              {/* Adventure Tours */}
                              <div className="mega-menu-column">
                                <h4>Adventure Tours</h4>
                                <li>
                                  <Link to="/TourPackage">Leh-Ladakh</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Manali</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Spiti Valley</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Rishikesh</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">
                                    Andaman and Nicobar Islands
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Coorg</Link>
                                </li>
                              </div>

                              {/* Cultural Tours */}
                              <div className="mega-menu-column">
                                <h4>Cultural Tours</h4>
                                <li>
                                  <Link to="/TourPackage">Jaipur</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Udaipur</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Agra</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Hampi</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Khajuraho</Link>
                                </li>
                                <li>
                                  <Link to="/TourPackage">Varanasi</Link>
                                </li>
                              </div>

                              {/* Nature & Wildlife Tours */}
                              <div className="mega-menu-column">
                                <h4>Nature & Wildlife Tours</h4>
                                <li>
                                  <Link to="/gallery">
                                    Jim Corbett National Park
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/faq">
                                    Ranthambore National Park
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/team">
                                    Kaziranga National Park
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/team-details">
                                    Kerala Backwaters
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/pricing">Sundarbans</Link>
                                </li>
                                <li>
                                  <Link to="/shop">The Nilgiris</Link>
                                </li>
                              </div>
                            </div>
                          </ul>
                        </li>

                        <li className="has-sub mega-menu">
                          <Link to="/blog">International Destinations</Link>
                          <ul className="mega-menu-content">
                            <div className="mega-menu-row">
                              {/* Exotic Beach Holidays */}
                              <div className="mega-menu-column">
                                <h4>Exotic Beach Holidays</h4>
                                <li>
                                  <Link to="/blog">Maldives</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Bali, Indonesia
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Phuket, Thailand
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Mauritius</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Seychelles</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Fiji</Link>
                                </li>
                              </div>

                              {/* Historical & Cultural Tours */}
                              <div className="mega-menu-column">
                                <h4>Historical & Cultural Tours</h4>
                                <li>
                                  <Link to="/blog-details">Rome, Italy</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Athens, Greece</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Cairo, Egypt</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Kyoto, Japan</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Istanbul, Turkey
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Petra, Jordan</Link>
                                </li>
                              </div>

                              {/* Adventure & Wildlife Tours */}
                              <div className="mega-menu-column">
                                <h4>Adventure & Wildlife Tours</h4>
                                <li>
                                  <Link to="/blog-details">
                                    Serengeti, Tanzania
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Amazon Rainforest, Brazil
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Great Barrier Reef, Australia
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Swiss Alps, Switzerland
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Rocky Mountains, Canada
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Patagonia, Argentina
                                  </Link>
                                </li>
                              </div>

                              {/* Luxury Escapes */}
                              <div className="mega-menu-column">
                                <h4>Luxury Escapes</h4>
                                <li>
                                  <Link to="/blog-details">Paris, France</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Dubai, UAE</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">New York, USA</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Santorini, Greece
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Bora Bora, French Polynesia
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Cape Town, South Africa
                                  </Link>
                                </li>
                              </div>
                            </div>
                          </ul>
                        </li>

                        <li className="has-sub">
                          <Link to="/blog">Weekend Trips</Link>
                          <ul className="submenu">
                            <li>
                              <Link to="/blog-details">
                                Kasol-Kheerganga Trek
                              </Link>
                            </li>
                            <li>
                              <Link to="/blog-details">Triund Trek</Link>
                            </li>
                            <li>
                              <Link to="/blog-details">Nag Tibba Trek</Link>
                            </li>
                            <li>
                              <Link to="/blog-details">Kedarkantha Trek</Link>
                            </li>
                            <li>
                              <Link to="/blog-details">Hampta Pass Trek</Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                    <div className="flex items-center justify-end">
                      <Link to="/login" className="common-button me-3">
                        Login
                      </Link>
                      <Link to="/signup" className="secondary-button">
                        Sign Up
                      </Link>
                    </div>

                    {/* Mobile Menu */}
                    <nav
                      id="mobile-menu"
                      className={`${menuOpen ? "menu-open" : ""} d-lg-none`}
                    >
                      <ul className="text-left">
                        {/* Domestic Destinations with full mega menu */}
                        <li
                          className={`has-sub ${
                            submenuOpen["domestic"] ? "open-submenu" : ""
                          }`}
                        >
                          <Link
                            to="#"
                            onClick={() => toggleSubmenu("domestic")}
                          >
                            Domestic Destinations
                          </Link>
                          <ul className="mega-menu-content">
                            <div className="mega-menu-row">
                              {/* Repeating Domestic Destinations from desktop */}
                              <div className="mega-menu-column">
                                <h4>Spiritual Tours</h4>
                                <li>
                                  <Link to="/destination">Varanasi</Link>
                                </li>
                                <li>
                                  <Link to="/home-02">Prayagraj</Link>
                                </li>
                                <li>
                                  <Link to="/home-03">Ayodhya</Link>
                                </li>
                                <li>
                                  <Link to="/home-04">Rishikesh</Link>
                                </li>
                                <li>
                                  <Link to="/home-05">Haridwar</Link>
                                </li>
                                <li>
                                  <Link to="/home-06">Bodh Gaya</Link>
                                </li>
                              </div>

                              {/* Adventure Tours */}
                              <div className="mega-menu-column">
                                <h4>Adventure Tours</h4>
                                <li>
                                  <Link to="/room">Leh-Ladakh</Link>
                                </li>
                                <li>
                                  <Link to="/room-details">Manali</Link>
                                </li>
                                <li>
                                  <Link to="/room-details">Spiti Valley</Link>
                                </li>
                                <li>
                                  <Link to="/room-details">Rishikesh</Link>
                                </li>
                                <li>
                                  <Link to="/room-details">
                                    Andaman and Nicobar Islands
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/room-details">Coorg</Link>
                                </li>
                              </div>

                              {/* Cultural Tours */}
                              <div className="mega-menu-column">
                                <h4>Cultural Tours</h4>
                                <li>
                                  <Link to="/service">Jaipur</Link>
                                </li>
                                <li>
                                  <Link to="/service-details">Udaipur</Link>
                                </li>
                                <li>
                                  <Link to="/service-details">Agra</Link>
                                </li>
                                <li>
                                  <Link to="/service-details">Hampi</Link>
                                </li>
                                <li>
                                  <Link to="/service-details">Khajuraho</Link>
                                </li>
                                <li>
                                  <Link to="/service-details">Varanasi</Link>
                                </li>
                              </div>

                              {/* Nature & Wildlife Tours */}
                              <div className="mega-menu-column">
                                <h4>Nature & Wildlife Tours</h4>
                                <li>
                                  <Link to="/gallery">
                                    Jim Corbett National Park
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/faq">
                                    Ranthambore National Park
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/team">
                                    Kaziranga National Park
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/team-details">
                                    Kerala Backwaters
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/pricing">Sundarbans</Link>
                                </li>
                                <li>
                                  <Link to="/shop">The Nilgiris</Link>
                                </li>
                              </div>
                            </div>
                          </ul>
                        </li>

                        {/* International Destinations with submenu */}
                        <li
                          className={`has-sub ${
                            submenuOpen["international"] ? "open-submenu" : ""
                          }`}
                        >
                          <Link
                            to="#"
                            onClick={() => toggleSubmenu("international")}
                          >
                            International Destinations
                          </Link>
                          <ul className="mega-menu-content">
                            <div className="mega-menu-row">
                              {/* Repeating International Destinations from desktop */}
                              <div className="mega-menu-column">
                                <h4>Exotic Beach Holidays</h4>
                                <li>
                                  <Link to="/blog">Maldives</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Bali, Indonesia
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Phuket, Thailand
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Mauritius</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Seychelles</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Fiji</Link>
                                </li>
                              </div>

                              {/* Historical & Cultural Tours */}
                              <div className="mega-menu-column">
                                <h4>Historical & Cultural Tours</h4>
                                <li>
                                  <Link to="/blog-details">Rome, Italy</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Athens, Greece</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Cairo, Egypt</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Kyoto, Japan</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Istanbul, Turkey
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Petra, Jordan</Link>
                                </li>
                              </div>

                              {/* Adventure & Wildlife Tours */}
                              <div className="mega-menu-column">
                                <h4>Adventure & Wildlife Tours</h4>
                                <li>
                                  <Link to="/blog-details">
                                    Serengeti, Tanzania
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Amazon Rainforest, Brazil
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Great Barrier Reef, Australia
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Swiss Alps, Switzerland
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Rocky Mountains, Canada
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Patagonia, Argentina
                                  </Link>
                                </li>
                              </div>

                              {/* Luxury Escapes */}
                              <div className="mega-menu-column">
                                <h4>Luxury Escapes</h4>
                                <li>
                                  <Link to="/blog-details">Paris, France</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">Dubai, UAE</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">New York, USA</Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Santorini, Greece
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Bora Bora, French Polynesia
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog-details">
                                    Cape Town, South Africa
                                  </Link>
                                </li>
                              </div>
                            </div>
                          </ul>
                        </li>

                        {/* Weekend Trips with submenu */}
                        <li
                          className={`has-sub ${
                            submenuOpen["weekend"] ? "open-submenu" : ""
                          }`}
                        >
                          <Link to="#" onClick={() => toggleSubmenu("weekend")}>
                            Weekend Trips
                          </Link>
                          <ul className="submenu">
                            <li>
                              <Link to="/blog-details">
                                Kasol-Kheerganga Trek
                              </Link>
                            </li>
                            <li>
                              <Link to="/blog-details">Triund Trek</Link>
                            </li>
                            <li>
                              <Link to="/blog-details">Nag Tibba Trek</Link>
                            </li>
                            <li>
                              <Link to="/blog-details">Kedarkantha Trek</Link>
                            </li>
                            <li>
                              <Link to="/blog-details">Hampta Pass Trek</Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Main;
