import React, { useState } from "react";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is The Tripping Tales?",
      answer:
        "The Tripping Tales is a full-service travel agency offering curated trips for families, solo travelers, couples, and corporate clients. We specialize in personalized itineraries, unique experiences, and exclusive offers for destinations across the globe.",
    },
    {
      question: "How can I book a trip with The Tripping Tales?",
      answer:
        "Booking with The Tripping Tales is simple! You can explore our website, choose a destination, and fill out the inquiry form, or contact us directly via phone or email. Our travel experts will guide you through the booking process and tailor the trip to your needs.",
    },
    {
      question: "What destinations does The Tripping Tales offer?",
      answer:
        "We offer a wide range of destinations, including popular locations like Thailand, Vietnam, Singapore, Dubai, Kerala, and the Andaman Islands. We also have special packages for Uttarakhand and other off-the-beaten-path destinations.",
    },
    {
      question: "Do you offer customizable travel packages?",
      answer:
        "Yes, The Tripping Tales specializes in customized travel experiences. Whether you're planning a family vacation, a solo adventure, or a honeymoon, our travel consultants work with you to create the perfect itinerary based on your preferences and budget.",
    },
    {
      question: "What are the payment options available?",
      answer:
        "We accept multiple payment methods, including credit cards, debit cards, bank transfers, and digital wallets. A detailed payment process will be provided during the booking.",
    },
    {
      question: "Are there any hidden fees or charges?",
      answer:
        "No, The Tripping Tales believes in transparency. All costs related to your trip will be shared upfront, with no hidden fees. However, any additional requests or changes made after booking may incur extra charges.",
    },
    {
      question: "Can I make changes to my travel itinerary after booking?",
      answer:
        "Yes, you can modify your itinerary based on availability and policies. Please reach out to your travel consultant as soon as possible to make changes.",
    },
    {
      question: "Do you provide travel insurance?",
      answer:
        "While we do not provide insurance directly, we highly recommend purchasing travel insurance for coverage on medical emergencies, trip cancellations, or lost baggage. We can assist in connecting you with trusted travel insurance providers.",
    },
    {
      question: "What is your cancellation policy?",
      answer:
        "Our cancellation policy varies depending on the destination, type of package, and booking timeline. You will be informed of the exact terms during the booking process. Please read our Terms and Conditions for more details.",
    },
    {
      question: "How do I know if my booking is confirmed?",
      answer:
        "Once your booking is confirmed, you will receive a confirmation email with all the trip details, including the itinerary, payment receipt, and travel documents.",
    },
    {
      question: "Do you offer corporate travel services?",
      answer:
        "Yes, we offer comprehensive corporate travel services, including event planning, group travel, and team-building trips. Reach out to our team for customized corporate packages.",
    },
    {
      question: "Can I earn rewards or discounts on my bookings?",
      answer:
        "Yes, The Tripping Tales often runs special promotions and offers loyalty rewards for repeat customers. Subscribe to our newsletter or follow us on social media to stay updated on our latest deals.",
    },
    {
      question: "How can I contact The Tripping Tales customer support?",
      answer:
        "You can contact us via email, phone, or through our website’s contact form. Our customer support team is available to assist you with any inquiries or concerns.",
    },
    // Existing FAQs
    {
      question: "What is the best time to visit Bali?",
      answer:
        "The best time to visit Bali is during the dry season, from April to October, when the weather is pleasant and ideal for outdoor activities.",
    },
    {
      question: "Do I need a visa to travel to Europe?",
      answer:
        "Most non-European citizens need a Schengen visa to visit Europe. However, this depends on the country you are from and the European country you are visiting.",
    },
    {
      question: "How can I book a tour with The Tripping Tales?",
      answer:
        "You can book a tour directly on our website by visiting the 'Tours' section, selecting your desired package, and filling out the booking form.",
    },
    {
      question: "What are the cancellation policies for tours?",
      answer:
        "Our cancellation policies vary depending on the tour package. Please refer to the specific package page for detailed cancellation policies.",
    },
    {
      question: "Is travel insurance necessary?",
      answer:
        "While not mandatory for all destinations, we highly recommend purchasing travel insurance to cover any unforeseen incidents such as medical emergencies, trip cancellations, or lost luggage.",
    },
  ];

  return (
    <div className="max-w-8xl mx-auto px-4 py-12 bg-gradient-to-r from-[#D1E8FF] to-[#FFC0D7] rounded-lg shadow-2xl mb-9">
      <h2 className="text-4xl font-extrabold text-left text-gray-800 mb-10 tracking-wide">
        Frequently Asked Questions
      </h2>
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`mb-6 p-4 bg-white rounded-lg shadow-lg transition-all duration-300 ${
            activeIndex === index ? "shadow-2xl" : ""
          }`}
        >
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full flex justify-between items-center text-left text-xl font-semibold text-[var(--primary-color)]"
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-[var(--secondary-color)] transform rotate-180 transition-transform"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-[var(--secondary-color)] transition-transform"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              )}
            </span>
          </button>
          <div
            className={`${
              activeIndex === index ? "max-h-screen" : "max-h-0"
            } overflow-hidden transition-max-height duration-500 ease-in-out`}
          >
            <p className="mt-3 text-lg text-gray-700">{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
