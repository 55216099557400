import React from "react";

const CopyrightPolicy = () => {
  return (
    <div className="mx-auto px-0 pb-5 bg-gradient-to-r from-[#D1E8FF] to-[#FFC0D7]">
      <div className="rounded-lg p-6 lg:p-12 w-100 container">
        <h1 className="text-3xl lg:text-4xl font-bold text-left text-[#02123c] mb-4">
          Copyright Policy
        </h1>
      

        <div className="space-y-6 text-[#02123c]">
          <p className="text-justify text-lg">
            <strong>The Tripping Tales</strong> operates as a platform where local suppliers and vendors contribute content. We have established internal guidelines to align the interests of all parties and to ensure that no material inconsistent with our terms and conditions is present on our website or in any physical form bearing The Tripping Tales branding.
          </p>
          <p className="text-justify text-lg">
            Visitors to the website are prohibited from reproducing, performing, creating derivative works from, republishing, or uploading any content from the website without the prior written consent of The Tripping Tales. However, users are permitted to download any items specifically marked as downloadable for personal, non-commercial home use, provided that all copyright and other notices embedded in the content remain intact.
          </p>
          <p className="text-justify text-lg">
            For the purposes of this policy, references to The Tripping Tales, "us," or "the company" denote Rediscover Paradise Travel Services Pvt. Ltd., and any mention of "content" encompasses all forms of content, including but not limited to media-based content such as images, videos, and presentation slides.
          </p>

          <h2 className="text-2xl font-semibold my-2">
            Content and Media Guidelines for Local Guides/Vendors/Tour Suppliers/Partners
          </h2>

          <h3 className="text-xl font-semibold">
            Original Content Requirement
          </h3>
          <p className="text-justify text-lg">
            All content uploaded to The Tripping Tales must be 100% unique. Content copied from any other website or any digital or physical source is strictly prohibited. If you have the same itinerary on your own website, you must provide substantial proof of ownership. We recommend rewriting such content to ensure it differs in structure but conveys the same meaning. Should you require assistance with content rewriting, we may provide support from our content writers.
          </p>

          <h3 className="text-xl font-semibold">
            Exclusive Conduct of Experiences/Tours
          </h3>
          <p className="text-justify text-lg">
            You must conduct the experience/tour personally; outsourcing to any third party is not permitted. By uploading an experience/tour to our website, you confirm that you will be responsible for the full execution of the experience/tour.
          </p>

          <h3 className="text-xl font-semibold">
            Ownership of Media
          </h3>
          <p className="text-justify text-lg">
            You must hold full rights to any media uploaded, including videos, pictures, slides, and marketing materials. The Tripping Tales reserves the right to use this media without requiring attribution or credit.
          </p>

          <h3 className="text-xl font-semibold">
            Copyright and Ownership
          </h3>
          <p className="text-justify text-lg">
            You must possess sole and entire copyright for all information and media uploaded. Content under Creative Commons licenses is prohibited. In the event of a copyright violation, as reported by company officials, the public, or the material's rightful owner, the infringing content will be removed, and you will be held responsible for any infringement. This may result in the removal of all your listings and a permanent ban from being a supplier.
          </p>

          <h3 className="text-xl font-semibold">
            Content Accuracy and Integrity
          </h3>
          <p className="text-justify text-lg">
            Mismatches between media and content are considered a serious offense. If images and descriptions do not align or if there is intentional misrepresentation, the content will be removed, and you may face a ban.
          </p>

          <h3 className="text-xl font-semibold">
            Handling Copyright Claims
          </h3>
          <p className="text-justify text-lg">
            If a copyright claim is made, The Tripping Tales will provide your details to the claimant following our analysis of the issue. Claims of copyright violation reported by you will be addressed within three business days.
          </p>

          <h3 className="text-xl font-semibold">
            Display and Use of Listings
          </h3>
          <p className="text-justify text-lg">
            By submitting listings, you consent to their display on The Tripping Tales website and any affiliated company sites. You also agree to the use of your listings for promotional purposes.
          </p>

          <h2 className="text-xl font-semibold">
            Responsibility for Copyright Issues
          </h2>
          <p className="text-justify text-lg">
            You bear full responsibility for any copyright infringement issues related to the tours/activities you provide. While we conduct random checks, the ultimate responsibility for compliance with copyright laws rests with you.
          </p>

          <h3 className="text-xl font-semibold">
            Copyright Claims Process
          </h3>
          <p className="text-justify text-lg">
            If you believe that your copyrighted material has been infringed upon on our website, please follow the procedure outlined below to submit a copyright claim:
          </p>

          <h3 className="text-xl font-semibold">
            Submission of a Claim
          </h3>
          <p className="text-justify text-lg">
            Send an email detailing the following:
            <ul className="list-disc list-inside mb-4">
              <li>The specific experience/tour in violation of copyright.</li>
              <li>The exact content on that page that is alleged to infringe your copyright.</li>
              <li>Proof of your ownership of the material in question.</li>
            </ul>
          </p>

          <h3 className="text-xl font-semibold">
            Required Documentation
          </h3>
          <p className="text-justify text-lg">
            To support your claim, please provide:
            <ul className="list-disc list-inside mb-4">
              <li>The permanent URL of the page containing the allegedly infringing material.</li>
              <li>Details about the infringing content.</li>
              <li>Your contact information, including an email address and, if possible, a telephone number.</li>
              <li>Proof of copyright ownership, including:
                <ul className="list-disc list-inside mb-4">
                  <li>Proof of registration of the material under the DMCA (if applicable) or</li>
                  <li>A detailed description of the material, including where it was created, by whom, and the subject matter.</li>
                  <li>Evidence supporting your claim of ownership.</li>
                </ul>
              </li>
            </ul>
          </p>

          <h3 className="text-xl font-semibold">
            Review and Response
          </h3>
          <p className="text-justify text-lg">
            Upon receipt of your claim, The Tripping Tales will forward the details to the relevant vendor. Our team will follow up with the vendor, which may take up to seven business days and could extend to fourteen days. If the vendor does not respond within this period, we will assume the claim is valid, and the infringing content will be removed from our website on the seventh day.
          </p>

          <h3 className="text-xl font-semibold">
            Action Against False Claims
          </h3>
          <p className="text-justify text-lg">
            All claims will be treated seriously. Intentionally false claims or misrepresentation of facts will result in legal action against the claimant, including liability for damages, costs, and attorneys' fees.
          </p>

          <h3 className="text-xl font-semibold">
            Ownership of Submitted Content
          </h3>
          <p className="text-justify text-lg">
            All content submitted for review becomes the property of The Tripping Tales, and we reserve the right to use it at our discretion.
          </p>

          <h3 className="text-xl font-semibold">
            Images Terms of Use
          </h3>
          <p className="text-justify text-lg">
            The following types of images are prohibited from being uploaded to our website:
            <ul className="list-disc list-inside mb-4">
              <li>Images that incorporate free or purchased components from any external source unless you possess an express license authorizing such use.</li>
              <li>Images that feature government or military property, whether in whole or in part.</li>
              <li>Images that depict private locations unless you have explicit permission to use such images, which you must be able to provide upon request.</li>
              <li>Images that include individuals or recognizable human features unless you have express permission from the individuals depicted, which you must be able to provide upon request.</li>
            </ul>
          </p>

          <h2 className="text-2xl font-semibold my-2">
            Contact Us
          </h2>
          <p className="text-justify text-lg pb-10">
            For questions regarding our copyright policy or to submit a copyright claim, please contact us at <strong>info@thetrippingtales.com</strong>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CopyrightPolicy;
