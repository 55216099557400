import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import profilePic from "../images/avatrnew.png"; // Placeholder profile picture

function Testimonial() {
  const testimonials = [
    {
      author: "Devagya Vyas",
      review: "Our experience with Tripping Tales exceeded all expectations. Every detail was meticulously planned, making us feel genuinely valued and cared for. Highly recommend!",
      rating: 5,
      image: profilePic,
    },
    {
      author: "Bindu Nair",
      review: "The trip was incredible from start to finish. Our stay at Evitel Hotel was comfortable, and our guide Yuan was kind and professional. Rafting was an unforgettable thrill, and the beaches were breathtaking. I’d suggest trying the local Udipi restaurant. Overall, it was an amazing experience!",
      rating: 5,
      image: profilePic,
    },
    {
      author: "Shilpa",
      review: "I recently traveled with The Tripping Tales for an 8-day trip to Bali, and it was an outstanding experience. Their arrangements were seamless, from hotel accommodations to transportation. When we needed a more comfortable vehicle, they quickly upgraded us. The itinerary was well-planned, allowing us to explore at a leisurely pace without feeling rushed. Our driver, Yuan, was incredibly accommodating and made our trip even more enjoyable. I highly recommend The Tripping Tales for anyone looking to travel; you won't be disappointed!",
      rating: 5,
      image: profilePic
    },
    {
      author: "Anil Nair",
      review: "Tripping Tales arranged a perfect Bali trip for our group of ten. They took care of everyone’s needs, allowing us to make the most of our time together. A wonderful experience and highly recommended!",
      rating: 5,
      image: profilePic,
    },
    {
      author: "Sakshi Verma",
      review: "This was the best trip we’ve ever had! Tripping Tales managed every aspect of the journey, from accommodations to transfers, with A+ service. We couldn't have asked for more.",
      rating: 5,
      image: profilePic,
    },
    {
      author: "Aditi",
      review: "Tripping Tales planned a seamless, eco-friendly vacation for us. The guides were knowledgeable, and the customer service was prompt and attentive. Their commitment to responsible travel truly stands out. Highly recommended!",
      rating: 5,
      image: profilePic,
    },
    {
      author: "Rakesh Kumar",
      review: "For a stress-free and enjoyable travel experience, Tripping Tales is the way to go. They’re experts at what they do, and I’d recommend them to anyone.",
      rating: 5,
      image: profilePic,
    },
    {
      author: "Resale Emperio",
      review: "We felt like the sole priority of Tripping Tales. They personalized our trip perfectly, making it a memorable experience.",
      rating: 5,
      image: profilePic,
    },
    {
      author: "Anju Singh",
      review: "Thanks to Tripping Tales, we experienced the local culture and traditions firsthand. It was a beautiful and authentic experience.",
      rating: 5,
      image: profilePic,
    },
    {
      author: "Mohit Gurjar",
      review: "Our adventure with Tripping Tales was unforgettable. We did things we never imagined, creating memories that will last a lifetime.",
      rating: 5,
      image: profilePic,
    },
    {
      author: "Riya Dhamaniya",
      review: "The accommodations arranged by Tripping Tales were stunning and exceptionally comfortable. I’d definitely travel with them again.",
      rating: 5,
      image: profilePic,
    },
  ];

  const testimonialSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 767, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <section className="testimonial-section py-16 bg-gradient-to-br from-indigo-800 to-purple-900">
      <div className="container mx-auto px-6">
        <h2 className="text-center text-4xl font-extrabold text-white mb-12 testimonial-heading">What Our Clients Say</h2>

        <Slider {...testimonialSettings}>
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} testimonial={testimonial} />
          ))}
        </Slider>
      </div>

      {/* Custom Styles */}
      <style jsx>{`
        .testimonial-card-wrapper {
          perspective: 1200px;
          margin-left: 1rem; /* Adds left margin for spacing */
        }

        .testimonial-card {
          position: relative;
          background: rgba(255, 255, 255, 0.8); /* Light shade of white */
          backdrop-filter: blur(8px);
          border-radius: 15px;
          transition: transform 0.6s;
          overflow: hidden;
          border: 1px solid rgba(255, 255, 255, 0.3);
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
          margin: 0 1rem; /* Adds space between cards */
        }

        .testimonial-card:hover {
          transform: rotateY(3deg) rotateX(3deg) scale(1.05);
          box-shadow: 0 12px 30px rgba(0, 0, 0, 0.25);
        }

        .glass-effect {
          background: rgba(255, 255, 255, 0.2);
          border-radius: 1rem;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
          overflow: hidden;
          position: relative;
          backdrop-filter: blur(12px);
        }

        .overlay {
          transition: opacity 0.5s;
        }

        .testimonial-card:hover .overlay {
          opacity: 0.35;
        }

        .testimonial-card img {
          transition: transform 0.4s;
        }

        .testimonial-card img:hover {
          transform: scale(1.1);
        }
      `}</style>
    </section>
  );
}

function TestimonialCard({ testimonial }) {
  const [showFullReview, setShowFullReview] = useState(false);

  return (
    <div className="testimonial-card-wrapper">
      <div className="testimonial-card bg-white p-8 rounded-lg shadow-lg border-0 relative hover:shadow-2xl transition-transform duration-500 transform hover:scale-105 hover:translate-y-[-10px] glass-effect">
        <div className="overlay absolute inset-0 bg-gradient-to-r from-white-800 to-white-500 opacity-5 rounded-lg"></div>
        
        <div className="relative z-10 text-center flex flex-col items-center">
          <img
            src={testimonial.image}
            alt={`Profile picture of ${testimonial.author}`}
            className="ellipse w-16 h-16 mb-4 border-4 transform transition-transform duration-300 hover:scale-110"
          />
          <p className="text-black text-base mb-4 italic font-light">
            "{showFullReview ? testimonial.review : `${testimonial.review.slice(0, 100)}...`}"
          </p>
          <button
            onClick={() => setShowFullReview(!showFullReview)}
            className="text-blue-800 font-semibold text-sm"
          >
            {showFullReview ? "Read Less" : "Read More"}
          </button>
          <h6 className="text-xl font-semibold text-black mt-2">{testimonial.author}</h6>
        </div>

        <div className="flex justify-center mt-2 relative z-10">
          <div className="flex items-center">
            {[...Array(5)].map((_, i) => (
              <svg
                key={i}
                className={`h-6 w-6 ${i < testimonial.rating ? "text-yellow-400" : "text-gray-300"}`}
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
              </svg>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
