import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container bg-gradient-to-r from-[#D1E8FF] to-[#FFC0D7] mb-10 text-[#02123c] py-10 px-4 mb-10">
      <div className="container max-w-5xl mx-auto">
        <h1 className="text-4xl font-bold mb-6 text-left pb-3">Privacy Policy</h1>
       
        <p className="text-lg mb-6">
          This Privacy Policy governs the use of www.thetrippingtales.com, which is operated by The Tripping Tales. It outlines the commitment to safeguarding the privacy of our users and describes how personal information is collected, used, and protected.
        </p>

        <h2 className="text-2xl font-semibold mb-4">User Privacy</h2>
        <p className="text-lg mb-6">
          We are dedicated to maintaining the highest standards of privacy for our users. This policy explains our approach to data collection and how we ensure the security of any personal information you may provide while using our website.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Scope and Compliance</h2>
        <p className="text-lg mb-6">
          The Tripping Tales adheres to all applicable data protection laws in India. We employ a proactive approach to user privacy and take all necessary steps to ensure the security of user data throughout their interaction with our website.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Use of Cookies</h2>
        <p className="text-lg mb-6">
          The Tripping Tales uses cookies to enhance the user experience when visiting our website. Where applicable, we have implemented a cookie control system that allows users, upon their first visit, to accept or decline the use of cookies on their device. This is in compliance with current legislation, which requires explicit user consent before placing or accessing cookies on a user’s device.
          Cookies are small files stored on the user’s hard drive, designed to track and store information regarding interactions with the website. These files enable our server to provide users with a personalized experience while navigating our site. Users who prefer to restrict the use of cookies can do so by adjusting their browser’s security settings to block all cookies from this website and its third-party service providers.
          We utilize tracking software, such as Google Analytics, to gain insights into visitor behavior and usage patterns. This software uses cookies to monitor engagement with our website. While these cookies track usage, they do not collect or store any personally identifiable information. For more information on Google’s privacy practices, please refer to their privacy policy here.
          Additionally, cookies from external vendors may be stored on users' devices when our website utilizes referral programs, sponsored links, or advertisements. These cookies are generally used for conversion and referral tracking purposes and typically expire after 30 days, although some may persist longer. No personal information is collected, saved, or stored by these cookies.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Email Newsletter</h2>
        <p className="text-lg mb-6">
          By subscribing to our email newsletter, you agree to receive periodic communications from The Tripping Tales, including updates, promotions, and relevant travel information. We strive to provide content that is valuable, informative, and secure. However, we recommend exercising caution when clicking on any external links included in our newsletters. These links may lead to third-party websites, and while we make every effort to ensure their reliability, The Tripping Tales cannot be held responsible for the content, security, or privacy practices of external sites.
        </p>

        <h2 className="text-2xl font-semibold mb-4">External Links</h2>
        <p className="text-lg mb-6">
          While our website only includes external links to websites we believe are of high quality, safe, and relevant, we encourage users to exercise caution before clicking on any such links. External links refer to clickable text, banners, or image links that direct users to third-party websites.
          Despite our best efforts, we cannot guarantee or verify the content or security of any externally linked websites. Therefore, users should be aware that clicking on external links is done at their own risk. The Tripping Tales and its owners cannot be held responsible for any losses, damages, or implications that may arise from visiting these external links.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Adverts and Sponsored Links</h2>
        <p className="text-lg mb-6">
          This website may feature sponsored links and advertisements, which are typically provided by our advertising partners. These third-party partners may have their own privacy policies governing the adverts they serve.
          When you click on such adverts, you will be redirected to the advertiser's website through a referral program, which may use cookies to track referral activity from our site. These cookies may be stored on your device's hard drive. Users are advised to proceed with caution when clicking on sponsored external links, as The Tripping Tales and its owners cannot be held responsible for any damages, losses, or consequences resulting from visits to these external websites.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Social Media Platforms</h2>
        <p className="text-lg mb-6">
          Interactions, communications, and engagements conducted via external social media platforms in which The Tripping Tales participates are subject to the terms, conditions, and privacy policies of each respective platform.
          Users are encouraged to exercise caution when using social media platforms and to carefully consider their own privacy and personal information. The Tripping Tales and its owners will never request sensitive or personal information through social media. If users wish to discuss confidential matters, we recommend contacting us directly through primary communication channels, such as email or telephone.
          Our website may include social sharing buttons, allowing users to share content directly from our site to their social media accounts. Before using these sharing tools, users are advised to do so at their own discretion, keeping in mind that the respective social media platform may track and store details of the sharing activity.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Shortened Links in Social Media</h2>
        <p className="text-lg mb-6">
          The Tripping Tales and its owners may share links to relevant web pages via their social media accounts. In some cases, these URLs may be shortened by the social media platform for convenience.
          Users are advised to exercise caution and sound judgment before clicking on shortened URLs shared through our social media accounts. While we strive to ensure that only legitimate links are posted, social media platforms can be vulnerable to spam and hacking. Therefore, The Tripping Tales and its owners cannot be held responsible for any damages or consequences arising from the use of shortened links.
        </p>

        <h2 className="text-2xl font-semibold mb-4">User Information</h2>
        <p className="text-lg mb-6">
          To access certain services on our website, users may be required to provide specific information during the registration process, including but not limited to:
          <ul className="list-disc list-inside mb-4">
            <li>Name</li>
            <li>Email address</li>
            <li>Gender</li>
            <li>Age</li>
            <li>PIN code</li>
            <li>Credit or debit card details</li>
            <li>Medical records and history</li>
            <li>Sexual orientation</li>
            <li>Biometric information</li>
            <li>Password</li>
            <li>Occupation, interests, and other related information</li>
          </ul>
          The information provided by users enables us to enhance our website and offer a more user-friendly experience. All required information is service-dependent, and we may use the provided information to maintain, protect, and improve our services (including advertising) and to develop new services.
          Please note that information freely available in the public domain or provided under the Right to Information Act, 2005, or any other applicable law, will not be considered sensitive.
        </p>

        <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
        <p className="text-lg mb-6">
          To provide you with the best possible experience, we may collect the following information:
          <ul className="list-disc list-inside mb-4">
            <li>Personal Information: Name, email address, phone number, postal address, age, gender, payment details, and other personally identifiable information.</li>
            <li>Non-Personal Information: Browser type, operating system, IP address, and usage data for analytics and performance enhancement.</li>
            <li>Sensitive Information: If necessary for specific services, we may collect sensitive information such as health-related data, biometric data, and other necessary identifiers. However, we only collect sensitive information with your explicit consent.</li>
          </ul>
        </p>

        <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
        <p className="text-lg mb-6">
          The information we collect may be used for the following purposes:
          <ul className="list-disc list-inside mb-4">
            <li>To provide, maintain, and improve our services.</li>
            <li>To personalize user experience and provide tailored content and advertisements.</li>
            <li>To communicate with users about their accounts, inquiries, and service updates.</li>
            <li>To analyze user behavior for enhancing our website and services.</li>
            <li>To prevent fraudulent activity and ensure the security of our services.</li>
            <li>To comply with legal obligations and enforce our terms and conditions.</li>
          </ul>
        </p>

        <h2 className="text-2xl font-semibold mb-4">3. Data Retention</h2>
        <p className="text-lg mb-6">
          We retain user information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. Once the retention period expires, we securely delete or anonymize the information.
        </p>

        <h2 className="text-2xl font-semibold mb-4">4. Your Rights</h2>
        <p className="text-lg mb-6">
          You have the following rights regarding your personal information:
          <ul className="list-disc list-inside mb-4">
            <li>The right to access your personal data.</li>
            <li>The right to request correction or deletion of your personal data.</li>
            <li>The right to object to or restrict processing of your personal data.</li>
            <li>The right to withdraw consent for processing your personal data.</li>
          </ul>
        </p>

        <h2 className="text-2xl font-semibold mb-4">5. Data Security</h2>
        <p className="text-lg mb-6">
          We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, loss, or misuse. However, no method of transmission over the internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee its absolute security.
        </p>

        <h2 className="text-2xl font-semibold mb-4">6. Changes to This Privacy Policy</h2>
        <p className="text-lg mb-6">
          We reserve the right to modify this Privacy Policy at any time. Any changes will be posted on this page with an updated effective date. We encourage users to periodically review this Privacy Policy for any updates or changes.
        </p>

        <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
        <p className="text-lg mb-6">
          If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at: info@thetrippingtales.com
        </p>

        <h2 className="text-2xl font-semibold mb-4">8. Your Consent</h2>
        <p className="text-lg mb-20">
          By using our website, you consent to the collection and use of your personal information as outlined in this Privacy Policy.
        </p>

      </div>
    </div>
  );
};

export default PrivacyPolicy;
